
<template>
  <!-- <n-layout-header bordered>
    <SiteHeader />
  </n-layout-header> -->
  <n-layout content-style="min-height: 100%;">
    <n-layout
      :content-style="this.isMobile ? 'padding: 8px; max-height: 90vh' : 'padding: 64px; max-height: 93vh'"
      :native-scrollbar="false"
    >
      <n-space vertical :size="30">
        <n-h1 prefix="bar" type="primary">Temų nustatymai</n-h1>
        <!-- <n-card title="Temų nustatymai"> -->
          <nested-draggable :tasks='list' @removeItem="remove"/>
          <n-button @click="add" style="width: 100%" type="primary" dashed>
            <template #icon>
              <n-icon><add-outline /></n-icon>
            </template>
          </n-button>
          <n-button style="width: 100%" type="primary" @click="save" :loading="isSaving" :disabled="isSaving">
            Išsaugoti pakeitimus
          </n-button>
        <!-- </n-card> -->
        <br><br>
      </n-space>
    </n-layout>
  </n-layout>
</template>
<script>

// import SiteHeader from '@/components/SiteHeader.vue'
import { useIsMobile } from '@/utils/composables'
import { useStore } from 'vuex'
import NestedDraggable from "@/pages/admin/nested-test.vue";
import { ref, reactive } from 'vue'
import { AddOutline } from '@vicons/ionicons5'

import { useMessage } from 'naive-ui'

const isMobileRef = useIsMobile()

export default {
  name: 'root',
  components: {
    // SiteHeader,
    NestedDraggable,
    AddOutline,
  },
  setup() {
    const store = useStore()
    const message = useMessage()
    return {
      isMobile: isMobileRef,
      list: reactive([...store.getters['menu/getMenuForEdditing']] || []),
      store,
      isSaving: ref(false),
      message,
    }
  },
  methods: {
    add: function() {
      this.list.push({ label: "//", disabled: true, key: String(Math.random()), children: [] });
    },
    remove: function(key) {
      function findAndRemoveByKey(item, key) {
        if (item.key === key) {
          console.log(item)
          return false
        }
        if (item.children && item.children.length > 0) {
          for (let item_ of item.children) {
            findAndRemoveByKey(item_, key)
          }
        }
        return true
      }

      console.log(this.list.filter(item => findAndRemoveByKey(item, key)))

      // this.list = this.list.filter(item => {
      //   return item.key !== key
      // })
    },
    checkIfValidBeforeSaving(menu) {
      // https://stackoverflow.com/questions/63519075/find-deep-nested-array-depth
      function count(children) {
        return children.reduce((depth, child) => {
          return Math.max(depth, 1 + count(child.children));
        }, 0)
      }
      const depth = count(menu)
      if (depth > 2) {
        return false
      } else {
        return true
      }
    },
    save: function() {
      if (!this.checkIfValidBeforeSaving(this.list)) {
        this.message.warning(
          'Menu struktūra yra negalima.' +
          'Didžiausias galimas gylis yra 2 - t.y. modulio pavadinimas ir potemė.' +
          'Potemė savo potemių turėti negali.'
        )
        return
      }
      this.isSaving = true
      const id = this.store._state.data.menu.menu[0]._id
      this.store.dispatch('menu/putMenu', {"id": id, "menu": this.list}).then(resp => {
        if (resp.status == 200) {
          this.message.success('Išsaugota')
          console.log('lesson pushed to db', resp.data)
        } else {
          this.message.warning(resp.status)
        }
        // have to fetch the list again, else it will not refresh
        // this.list = [...this.store.getters['menu/getMenuForEdditing']] || []
        this.isSaving = false
      })
    }
  }
}
</script>
